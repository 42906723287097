<template>
  <b-container>
    <b-row class="flex-column min-vh-100" align-v="center" align-h="center">
      <div class="logo">
        <huddle-logo></huddle-logo>
      </div>
      <form class="w-100">
        <b-row
          class="flex-column"
          no-gutters
          align-h="center"
          align-content="center"
        >
          <h1 class="text-center">Forgot Password?</h1>
          <p class="form-description">
            Enter the email address you used when you joined and we'll send you
            instructions to reset your password
          </p>
          <b-input-group class="icon-prepend">
            <b-input-group-prepend>
              <span class="input-group-text">
                <b-icon-envelope-fill></b-icon-envelope-fill>
              </span>
            </b-input-group-prepend>
            <b-form-input placeholder="Enter your email" required type="email">
            </b-form-input>
          </b-input-group>

          <b-button variant="success" @click="requestPassword"
            >Send Reset Instruction</b-button
          >
        </b-row>
      </form>
      <router-link to="/">
        <b-button class="go-back" variant="link">
          <b-icon-arrow-left></b-icon-arrow-left>
          <span class="ml-1">Go Back</span>
        </b-button>
      </router-link>
    </b-row>
  </b-container>
</template>

<script>
import vue from "vue";
import HuddleLogo from "../../components/ui/huddle-logo";

export default vue.extend({
  name: "ForgotPassword",
  components: { HuddleLogo },
  methods: {
    requestPassword() {
      // call service
    },
  },
});
</script>

<style lang="scss" scoped>
.logo {
  margin-bottom: 3.875rem;
}

form {
  max-width: 35.625rem;
  padding: 3.125rem 3.75rem 3.75rem;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 57, 109, 0.03);
  border-radius: 10px;

  h1 {
    font-size: 1.625rem;
    font-weight: 500;
    color: var(--primary);
    line-height: 1.625rem;
  }

  p.form-description {
    font-weight: 300;
    font-size: 1rem;
    opacity: 0.5;
    line-height: 1.625rem;
    text-align: center;
    color: var(--primary);
    margin: 1rem 1rem 3.125rem;
  }

  .input-group .form-control::placeholder {
    opacity: 0.5;
  }

  .btn-success {
    background-color: var(--primary);
    margin-top: 1.875rem;
    color: var(--white);
    line-height: 1.625rem;
  }
}

.go-back {
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.625rem;
  margin-top: 3.625rem;
  color: #009348;

  &:focus {
    box-shadow: none;
  }

  &:hover,
  &:focus,
  &:active {
    text-decoration: underline;
  }
}
</style>
